import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import InfoBanks from '@assets/json/info-banks.json';
import validate_amounts from '@utils/json/validation-amounts.json'
/* Recibimos lo que nos manda banks */
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { environment } from '@enviroment/environment';
import { SimulateInvestmentService } from '@apiShared/simulate-investment/simulate-investment.service';
import { ValidateTurboCdtService } from "@apiShared/process-status/validate-turbo-cdt/validate-turbo-cdt.service";
import { CookieUtil } from '@utils/cookies';
import { Globals } from '@config/global';
import { UtilsMethods_Services } from '@utils/utils';
import { ModalSharedLoginComponent } from '../../modals-shared/login/login.component';
import { AllProcessViewComponent } from '../../modals-shared/all-process-view/all-process-view.component';
import { getDataCampaign } from '@utils/get-campaign';
import { NotificacionService } from '@utils/notificacion';
import { AlertConfirmComponent } from '../../resources-alerts/alert-confirm/alert-confirm.component';
import { CloseModal } from 'src/app/class/close-modal/close-modal';
import { Router } from '@angular/router';
import { BankInfoService } from "@apiShared/bank/bank-info/bank-info.service";
import { fadeInAnimationSuave } from '@class/animations/animations';
import { ReasonCancellationOpeningCDTComponent } from '../reason-cancellation-opening-cdt/reason-cancellation-opening-cdt.component';
import AdvisorClaudiaUribe from '@assets/json/advisor_claudia_uribe.json';
import { GtmService } from '@apiShared/analytics/gtm';

@Component({
  selector: 'app-check-parameters',
  templateUrl: './check-parameters.component.html',
  styleUrls: ['./check-parameters.component.css'],
  animations: [fadeInAnimationSuave]
})
export class CheckParametersComponent implements OnInit {

  env = environment;
  CheckParametersDataModal: any;
  /* Configuración del modal, cerrarlo */
  closeResult: string = '';
  showMobile = false;
  /*  */
  selectedBank: any;
  bank_image_view: any;
  submitCheckParam = false;
  /*  */
  screenBloqued = false;
  opacityModalCheckParameters = '1';
  /*  */
  FormCheckParameters!: FormGroup;
  /* Información de cada banco, sucursarles, etc, en formato JSON*/
  infoBanksJSON: any = [];
  /* El response se vendrá actualizando cada vez que el usaurio realice una nueva simulación dentro o fuera de Verificar Parametros */
  updatedJSON: any;
  responseOriginal: any;
  /* Con esto, siempre restauraremos la opción predeterminada de la selección. (caso para cuando el usuario escribe otra cantidad y activa el evento de desenfoque. Verifique los parámetros) */
  selectedItem: number = 24;
  PlaceCheckParameters: any;
  amtGenereteCheckParameters: any;
  placeGenereteCheckParameters: any;
  /*  */
  screen_desktop?: boolean;
  screen_mobile?: boolean;
  placeSelectItem: any;
  amount_create_process: any;
  /*  */
  bank_opencdt: any;
  bankID_opencdt: any;
  bankTerm_opencdt: any;
  bankRate_opencdt: any;
  /*  */
  terms: any;
  term_default!: number;
  step_notificacion_components: any;
  bank_amount_validate: any;
  was_attended_store: boolean = false;
  validate_number_advisor = false;
  promoBunos: boolean = false;
  eventBonus: string | null = null;
  send_check_phone_number: boolean = false;
  turboCDT = false;
  bankSettings: any = {
    digital_signature: true
  };

  getCampaign: any = {};
  is_amount_disables_turbocdt: boolean = false;

  advisor_claudiauribe: any[] = AdvisorClaudiaUribe;
  lineThroughInAmount: boolean = false;

  constructor(
    public fb: UntypedFormBuilder,
    public utils: UtilsMethods_Services,
    public breakpointObserver: BreakpointObserver,
    public cookies: CookieUtil,
    public globals_var: Globals,
    public activeModal: NgbActiveModal,
    private _closeModal: CloseModal,
    public _getDataCampaign: getDataCampaign,
    private notificacion_components: NotificacionService,
    private modalService: NgbModal,
    private api_simulate_investment: SimulateInvestmentService,
    private api_validate_turbo_cdt: ValidateTurboCdtService,
    private router: Router,
    private api_bank_info: BankInfoService,
    private gtm: GtmService
  ) {
    this._getDataCampaign.getCampaignDataUpdates().subscribe(data => {
      this.getCampaign = data;
    });
    
    /* Formgroup del formulario Verificar Parametros */
    this.FormCheckParameters = this.fb.group({
      amount: [this.utils.thousandsSeparator(this.cookies.get('amount')), [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(49999)]],
      number_advisor: [],
      method_pay_turbo: ['1']
    });

    this.receiveNotificacionHideBanner();

  }

  deactiveTurboCDT(event: any) {
    const selectedValue = event.target.value;
    this.turboCDT = selectedValue === '1';
  }

  receiveNotificacionHideBanner(): void {
    this.notificacion_components.receiveNotificacion.subscribe((data => {
      if (data.api === "send_check_phone_number") {
        this.send_check_phone_number = data.check;
        if (!data.check) {
          this.activateBannerPromoBonus(this.selectedItem * 30);
        }
      }
    }));
  }

  ngOnInit(): void {
    this.screenResolution();
    /* Obtendremos toda la información del banco, lo más importante es que sacaremos su id */
    const JsonInfoBanks = InfoBanks;
    for (let i in JsonInfoBanks) {
      this.infoBanksJSON.push(JsonInfoBanks[i]);
    }
    this.operatorView(this.CheckParametersDataModal);
    this.eventBonus = sessionStorage.getItem('event_bonus');
    this.onLineThroughInAmount(
      parseInt(this.cookies.get('amount'))
    );
  }

  /**TO DO: Usar este servicio para consultar desde la BD otras propiedades de la entidad, como por ejemplo, si es aliada o no, nombre, y otras, y dejar de usar los diccionarios JSON */
  getBankInfo(): void {
    this.api_bank_info.BANKS_INFO(this.bankID_opencdt).then((data: any) => {
      if (data.status === 200) {
        let result = data.body;
        this.bankSettings = result.settings;
        // this.bankTurboCDT = result.settings.turbo_cdt?.enabled;
      }
    });
  }

  operatorView(data: any) {
    /* Si el usuario ya tiene un monto guardado, se lo asignamos al campo de monto */
    this.term_default = data.place;

    if (data.place) {
      this.selectedItem = Number(data.place / 30);
    }

    /* Validamos el monto con el monto permitido del banco */
    this.bank_amount_validate = validate_amounts[data.bank];
    this.terms = data.data.map((item: { term: any; }) => item.term / 30);
    this.terms.sort((a: number, b: number) => b - a);
    /* Se abrira el modal cuando el componente bank-oferss nos comunique que apí es igual a open-bank, por tanto habilitaremos la vista y abriremos el modal */
    this.step_notificacion_components = data.step;
    this.turboCDT = data.turbo;
    this.updatedJSON = data.data;
    this.responseOriginal = data.data;
    this.getOpenCdtBank(data);
  }

  validateNumber(event: KeyboardEvent): boolean {
    const teclaPresionada = event.key;
    return /[0-9]/.test(teclaPresionada);
  }

  advisorStore(option: any) {
    if (option === 1) {
      return this.was_attended_store = true;
    }
    return this.was_attended_store = false
  }

  /*  */
  getOpenCdtBank(data: any) {
    let logo: any = this.getCampaign.base_logo_company;

    if (logo != 'None') {
      this.bank_image_view = logo;
    } else {
      this.bank_image_view = `${this.env.S3_STATIC_FILES}/images/web/logos/bancos/${data?.bank}.png`
    }

    this.selectedBank = data?.bank.replace(this.globals_var.FLAMINGO, "Coltefinanciera");
    this.bank_opencdt = this.selectedBank;
    this.bankID_opencdt = this.infoBanksJSON[0][data?.bank]['id'];
    /* Validamos si el parametro del bank es un banco aliado */
    if (this.utils.isAlliedBank(data?.bank)) {

      this.amount_create_process = data?.amount ?? this.cookies.get('amount');
      this.FormCheckParameters.get('amount')?.setValue(this.utils.thousandsSeparator(this.amount_create_process));

      /* Siempre, que el usuario de click en Abrir tu CDT, se cargará el monto y plazo del rango de 720 días del banco escogio */
      const getObjRes = this.responseOriginal.find((obj: { term: any; }) => obj.term === this.selectedItem * 30);
      this.amtGenereteCheckParameters = Math.round(getObjRes.roi);
      this.placeGenereteCheckParameters = getObjRes.rate;
      this.bankTerm_opencdt = this.selectedItem;
      this.bankRate_opencdt = this.placeGenereteCheckParameters;
    }
    this.getBankInfo();
  }

  /* Esta función leerá el valor de las opciones seleccionadas, y cada vez que se seleccione una opción, cambiará el valor de amtGenereteCheckParameters */
  ChangeSelectValue() {
    /* Obtenemos el valor de la opción seleccionada. (Cada valor tiene el plazo en días) */
    this.PlaceCheckParameters = this.selectedItem;
    this.bankTerm_opencdt = this.PlaceCheckParameters;
    /* Cambiamos el valor de amtGenereteCheckParameters y así se cambiará el valor en la vista */
    const getObjRes = this.updatedJSON.find((obj: { term: any; }) => obj.term === this.PlaceCheckParameters * 30);
    this.amtGenereteCheckParameters = Math.round(getObjRes.roi);
    this.placeGenereteCheckParameters = getObjRes.rate;
    this.bankRate_opencdt = this.placeGenereteCheckParameters;
    if (!this.send_check_phone_number) {
      this.activateBannerPromoBonus(this.selectedItem * 30);
    }
    if (this.bankSettings.turbo_cdt?.enabled) {
      this.validateTurboCDT(this.bankID_opencdt, false);
    } else {
      this.turboCDT = false;
      this.is_amount_disables_turbocdt = true;
    }
  }

  _getSimulationInvestment(body: any) {
    body.terms = body.terms.map((term: number) => term * 30);
    if (body.amount < this.bank_amount_validate) {
      this.submitCheckParam = true;
    } else {
      /* Bajamos la opacidad del modal verificar parametros y bloqueamos el div  */
      this.opacityModalCheckParameters = '.2';
      this.screenBloqued = true;
      /* Retornamos el servicio */
      this.api_simulate_investment.SIMULATE_INVESTMENT(body).then((data: any) => {
        /* Cuando todo este bien, la opacidad volverá a su normalidad y la vista bloqueada se ocultara */
        this.screenBloqued = false;
        this.opacityModalCheckParameters = '1';
        /* Para restablecer la información sobre las ganancias obtenidas y la tasa obtenida, debemos dar un nuevo valor a updateJson */
        this.updatedJSON = data.body.results;

        /* Restauramos el nuevo valor que nos arroja new-pdf con la nueva cantidad */
        this.amtGenereteCheckParameters = Math.round(this.updatedJSON.find((obj: { term: any; }) => obj.term === this.selectedItem * 30).roi);
        this.placeGenereteCheckParameters = this.updatedJSON.find((obj: { term: any; }) => obj.term === this.selectedItem * 30).rate;

        if (!this.send_check_phone_number) {
          this.activateBannerPromoBonus(this.selectedItem * 30);
        }
        if (this.bankSettings.turbo_cdt?.enabled) {
          this.validateTurboCDT(this.bankID_opencdt, false);
        } else {
          this.turboCDT = false;
          this.is_amount_disables_turbocdt = true;
        }
      });
    }
  }

  /* Si todo está bien al verificar los parametros, pasaremos a la vista de Iniciar Sesión/Escribir codigo */
  checkParameters() {
    /* Bajamos la opacidad del modal verificar parametros y bloqueamos el div  */
    this.opacityModalCheckParameters = '.2';
    this.screenBloqued = true;
    /* Esta variable tendra el control de las validaciones */
    this.submitCheckParam = true;
    /* Si el monto del campo de verificar parametros es mayor o igual a 50 barras, dejará continuar, si no retornará a default (error) */
    if (this.FormCheckParameters.controls['amount'].value?.split('.').join('') >= this.bank_amount_validate) {
      let extra: any = {};
      let amount = this.FormCheckParameters.controls['amount'].value?.split('.').join('');
      this.cookies.save('amount', amount, 1);

      if (this.bankID_opencdt == this.globals_var.ID_FLAMINGO && this.was_attended_store && !Boolean(this.FormCheckParameters.controls['number_advisor'].value)) {
        this.opacityModalCheckParameters = '1';
        this.screenBloqued = false;
      } else {
        this.validate_number_advisor = true;

        if(this.getCampaign?.name != this.globals_var.CAMPAIGN_MEJORCDT){
          extra.external_company = this.getCampaign?.name
        }

        if([24, 29].includes(this.bankID_opencdt)){
          if(this.cookies.check('device_simulation_token')){
            this.gtm.triggerGTMEvent('ga_js_btn_check_parameters_returning', 'CheckParametersComponent');
          }else {
            this.gtm.triggerGTMEvent('ga_js_btn_check_parameters_none', 'CheckParametersComponent');
          }
        }

        extra.flamingo_advisor = this.FormCheckParameters.controls['number_advisor'].value;
        extra.activate_bonus_50 = this.promoBunos;

        /* Si existe el id_token del usuario, no le pediremos iniciar sesión, si no llamaremos al componente all-process para que nos liste los procesos del usuario y demás */
        if (this.cookies.check('id_token_user') == true) {
          /*  Abrimos el modal de Ver todos los procesos del usuario */
          // this.openAllProcessModal(
          //   {
          //     "api": "active_all_process_component",
          //     "data": {
          //       "bank_id": this.bankID_opencdt,
          //       "bank_name": this.bank_opencdt,
          //       "bank_amount": this.cookies.get('amount'),
          //       "phone": this.cookies.get('phone-full'),
          //       "extra": extra,
          //       "bank_term": this.bankTerm_opencdt,
          //       "bank_rate": this.bankRate_opencdt,
          //     },
          //     "id_token": this.cookies.get('id_token_user'),
          //     "step": this.step_notificacion_components,
          //     "modal": "btn-close-confirm-parameters"
          //   }
          // )
          /* Volvemos a la normalidad el modal  */

          this.opacityModalCheckParameters = '1';
          this.screenBloqued = false;
          this.activeModal.dismiss();

          let newProcessData = {
            bank_id: this.bankID_opencdt,
            bank_name: this.bank_opencdt,
            bank_amount: amount,
            phone: this.cookies.get('phone-full'),
            extra: extra,
            bank_term: this.bankTerm_opencdt,
            bank_rate: this.bankRate_opencdt,
            turbo: this.turboCDT && !this.is_amount_disables_turbocdt ? this.turboCDT : false
          }
          this.router.navigate(['/mis-procesos'], { state: { newProcessData } });
        } else {
          /* Si no existe la cookie, no hay login, por tanto, vamos a necesitar mostrar el modal del Loogin. */
          /* En este caso mandamos un obj en el atributo data, el paso, ya que verificaremos si existe el usuario, esto con el fin de si crear el usuario o no */
          /* Mandamos un objeto indicandole la información de los parametros para crear el proceso a la vista de all-process */
          this.openLoginModal(
            {
              "data": {
                "step": this.step_notificacion_components,
                "disable_modal_close_button": false
              },
              "obj_info_obj_create_process": {
                "bank_id": this.bankID_opencdt,
                "bank_name": this.bank_opencdt,
                "bank_amount": this.cookies.get('amount'),
                "phone": this.cookies.get('phone-full'),
                "extra": extra,
                "bank_term": this.bankTerm_opencdt,
                "bank_rate": this.bankRate_opencdt,
                "turbo": this.turboCDT
              },
              "modal": "btn-close-confirm-parameters"
            }
          );
          /* Volvemos a la normalidad el modal  */
          this.opacityModalCheckParameters = '1';
          this.screenBloqued = false;
          this.activeModal.dismiss();
        }
      }

    } else {
      /* Volvemos a la normalidad el modal  */
      this.opacityModalCheckParameters = '1';
      this.screenBloqued = false;
      return
    } return
  }
  /* Función para abrir modal del login */
  openLoginModal(data: any) {
    const modalRefLogin = this.modalService.open(
      ModalSharedLoginComponent,
      {
        centered: true,
        size: 'sm',
        backdrop: 'static'
      }
    );
    modalRefLogin.componentInstance.LoginDataModal = data;
  }

  /* Función para abrir modal de ver todos los procesos */
  openAllProcessModal(data: any) {
    const modalRef = this.modalService.open(
      AllProcessViewComponent,
      {
        centered: true,
        size: 'lg width-all-process',
        backdrop: 'static'
      }
    );
    modalRef.componentInstance.AllProcessDataModal = data;
    ;
  }

  /* Función que permitará en la vista (html) acceder  a las diferentes propiedades el form. (Obtener values, validaciones, etc)*/
  get fCheckParam(): { [key: string]: AbstractControl } {
    return this.FormCheckParameters.controls;
  }

  /* Dependiendo la resolucion - desktop o mobile, cargara diferentes elementos */
  screenResolution() {
    /* Dependiendo de la vista responsive, cargará cierta div padre de la vista */
    this.breakpointObserver.observe(["(min-width: 767px)"]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        //Entonces si el width es superior a 767, será la versión Desktop mostrando su propio diseño
        this.screen_desktop = true;
        this.screen_mobile = false;
      } else {
        //Entonces si el width es inferio a 767, será la versión Mobile mostrando su propio diseño
        this.screen_desktop = false;
        this.screen_mobile = true;
        this.showMobile = true;
      }
    });
  }

  /* Separador de miles que ejecuta keyup del input */
  thousandSeparator(event: any) {
    if (event.which >= 37 && event.which <= 40) return;
    this.FormCheckParameters.get('amount')?.setValue(
      this.FormCheckParameters.value.amount.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    );
    this.onLineThroughInAmount(
      parseInt(this.FormCheckParameters.get('amount')?.value.split('.').join(''))
    );
  }

  /* Monstramos mensaje de PROMO de temporada que típicamente corresponde a un bono */
  activateBannerPromoBonus(terms: number) {
    const amount: any = Number(this.cookies.get('amount'));
    const promobonus = this.cookies.get('promo-bonus');
    const refPromo = this.cookies.get('ref');

    let refValidPromo = [
      "Sebas0624",
      "Tian0625",
      "LP_NicolasAbril",
      "LP_FinanciaTuVida",
      "LP_ClaudiaUribe",
      "LP_ParejaYFinanzas",
      "LP_AlterinativaInversiones",
      "LP_AlterinativaInversiones",
      "LP_ArenaAlfa",
    ];

    let refAmountPromo = [
      "Sebas0624",
    ]

    let any_term = [
      "Sebas0624"
    ]

    let amountCondition = 0;

    if (refValidPromo.includes(refPromo) && refAmountPromo.includes(refPromo)) {
      amountCondition = 950000;
    } else {
      amountCondition = 1000000;
    }

    if (
      (promobonus && parseInt(amount) >= amountCondition) &&
      (
        (terms >= 360) || any_term.includes(refPromo)
      )
    ) {
      this.promoBunos = true;
    } else {
      this.promoBunos = false;
    }

  }

  validateTurboCDT(bank_id: number, force_value_method: boolean = true): void {
    let amount = this.FormCheckParameters.get('amount')?.value?.split('.').join('');
    this.opacityModalCheckParameters = '.2';
    this.screenBloqued = true;
    this.api_validate_turbo_cdt.VALIDATE_TURBO_CDT({
      bank_id: bank_id,
      phone: this.cookies.get('phone-full'),
      amount: parseInt(amount),
      term: this.bankTerm_opencdt,
      rate: this.bankRate_opencdt
    }).then(data => {
      this.opacityModalCheckParameters = '1';
      this.screenBloqued = false;

      switch (data.status) {
        case 200:

          this.is_amount_disables_turbocdt = false;

          if(!force_value_method && this.FormCheckParameters.get('method_pay_turbo')?.value === '2') {
            this.turboCDT = false;
          } else {
            this.turboCDT = true;
          }

          break;

        case 202:
          const errorMessages: { [key: string]: string } = {
            "T001": "has abierto un proceso con este banco, por lo que tu información personal y financiera ya está registrada",
            "T002": "el monto supera 25 SMMLV",
            "T003": "has abierto procesos con MejorCDT entre todos juntos superan los 25 SMMLV",
            "T004": "el monto supera 25 SMMLV"
          };
          const messageError: string = errorMessages[data.body.internal_code] || data.body.message;
          this.turboCDT = false;
          this.is_amount_disables_turbocdt = true;
          this.openAlertConfirmation({
            title: `
                <div class="d-block text-center">
                  <i class="mi mi-md mi-bell"></i>
                  <div class="d-block">
                    ¡Lo sentimos!
                  </div>
                </div>
              `,
            message: `
                En esta ocasión,
                <span class="font-bold">
                  no cumples con los requisitos para continuar con el proceso de Turbo CDT porque
                  ${messageError}.
                </span>
                Sin embargo, no te preocupes, puedes seguir adelante con la apertura a través de nuestro proceso general, el cual es sencillo y totalmente seguro.
                <div class="d-block text-center my-2">
                  <a href="https://api.whatsapp.com/send?phone=573160234714&text=Hola%2C%20estoy%20a%20punto%20de%20abrir%20un%20proceso%2C%20pero%20no%20est%C3%A1%20activo%20el%20Turbo%20CDT" target="_blank" class="text-black-50">
                    Hablar con un asesor
                  </a
                </div>
              `,
            no_padding_header: false,
            textButtons: {
              confirm: {
                text: "Lo entiendo",
                id: 'select_accept_denial_referred_code'
              }
            }
          });
          break;

        default:
          this.is_amount_disables_turbocdt = true;
          this.turboCDT = false;
          break;
      }
    });
  }

  openAlertConfirmation(modalData: { title: string, message: string, textButtons: {}, no_padding_header: boolean }): void {
    const modalRef = this.modalService.open(AlertConfirmComponent,
      {
        centered: true,
        windowClass: 'modal-confirmation-process-warning-turbocdt'
      }
    );
    modalRef.componentInstance.modalData = modalData;
    modalRef.componentInstance.getResult().subscribe((result: boolean) => {
      if (result) {
        modalRef.close();
      }
    });
  }

  closeModalCheck(){
    this.FormCheckParameters.get('amount')?.setValue(this.cookies.get('amount'));
    this.activeModal.close();
    const modalRefCancellation = this.modalService.open(
      ReasonCancellationOpeningCDTComponent,
      {
        centered: true,
        size: 'md',
        backdrop: 'static'
      }
    );
    modalRefCancellation.componentInstance.data = this.CheckParametersDataModal;
  }

  onLineThroughInAmount(amount: number): void {
    this.lineThroughInAmount = this.eventBonus == 'MONEYCON' && amount >= 1000000 ? true : false;
  }

}
