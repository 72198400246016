import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes_app: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./routes/home/home.module').then( m => m.HomeModule)
  },

  {
    path: 'ahorro',
    loadChildren: () => import('./routes/ahorro/ahorro.module').then( m => m.AhorroModule)
  },

  {
    path: 'cdt-simulador',
    loadChildren: () => import('./routes/cdt-simulador-v2/cdt-simulador-v2.module').then( m => m.CdtSimuladorV2Module)
  },

  {
    path: 'hoy-los-bancos-ofrecen',
    loadChildren: () => import('./routes/hoy-los-bancos-ofrecen/hoy-los-bancos-ofrecen.module').then( m => m.HoyLosBancosOfrecenModule)
  },

  {
    path: 'quienes-somos',
    loadChildren: () => import('./routes/quienes-somos/quienes-somos.module').then( m => m.QuienesSomosModule)
  },

  {
    path: 'como-lo-hacemos',
    loadChildren: () => import('./routes/como-lo-hacemos/como-lo-hacemos.module').then( m => m.ComoLoHacemosModule)
  },

  {
    path: 'por-que-comparar',
    loadChildren: () => import('./routes/porque-comparar/porque-comparar.module').then( m => m.PorqueCompararModule)
  },

  {
    path: 'contactanos',
    loadChildren: () => import('./routes/contactenos/contactenos.module').then( m => m.ContactenosModule)
  },

  {
    path: 'verificando-firma',
    loadChildren: () => import('./routes/verificado-firma/verificado-firma.module').then( m => m.VerificadoFirmaModule)
  },

  {
    path: 'ops/dashboard',
    loadChildren: () => import('./routes/ops/ops.module').then( m => m.OpsModule)
  },

  {
    path: 'portal',
    loadChildren: () => import('./routes/portal/portal.module').then( m => m.PortalModule)
  },

  {
    path: '4B6XN8K2H7VY3W9M5Q1Z9A3JN7M5D1H8S4L0F2K',
    loadChildren: () => import('./routes/modo-dios/modo-dios.module').then( m => m.ModoDiosModule)
  },

  {
    path: 'app',
    children: [
      {
        path: '',
        loadChildren: () => import('./routes/app/app.module').then(m => m.AppModule)
      },
      {
        path: '**',
        redirectTo: '/app',
        pathMatch: 'full'
      }
    ]
  },

  {
    path: 'tratamientos-datos-personales-banco-w',
    loadChildren: () => import('./routes/legal/pages/term-conditions-bancow/term-conditions-bancow.module').then( m => m.TermConditionsBancowModule)
  },

  {
    path: 'legal/cdtfuturo',
    loadChildren: () => import('./routes/legal/pages/term-conditions-cdtfuturo/legal-cdtfuturo.module').then( m => m.LegalCdtfuturoModule)
  },

  {
    path: 'legal/bancamia',
    loadChildren: () => import('./routes/legal/pages/terms-conditions-bancamia/legal-bancamia.module').then( m => m.LegalBancamiaModule)
  },

  {
    path: 'legal/coltefinanciera',
    loadChildren: () => import('./routes/legal/pages/terms-conditions-coltefinanciera/legal-coltefinanciera.module').then( m => m.LegalColtefinancieraModule)
  },

  {
    path: 'legal/mibanco',
    loadChildren: () => import('./routes/legal/pages/terms-conditions-mibanco/legal-mibanco.module').then( m => m.LegalMibancoModule)
  },
  {
    path: 'legal/bono-regalo',
    loadChildren: () => import('./routes/legal/pages/terms-conditions-bonoregalo/terms-conditions-bonoregalo.module').then( m => m.TermsConditionsBonoregaloModule)
  },
  {
    path: 'legal/cdt-cupido',
    loadChildren: () => import('./routes/legal/pages/term-condition-cdtcupido/term-condition-cdtcupido.module').then( m => m.TermConditionCdtcupidoModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./routes/login/login.module').then( m => m.LoginModule)
  },

  {
    path: 'mis-procesos',
    loadChildren: () => import('./routes/mis-procesos/mis-procesos.module').then( m => m.MisProcesosModule)
  },

  {
    path: 'mejores-bancos-para-cdt-hoy',
    loadChildren: () => import('./routes/mejores-bancos-para-hoy/mejores-bancos-para-hoy.module').then( m => m.MejoresBancosParaHoyModule)
  },

  {
    path: 'sitemap',
    loadChildren: () => import('./utils/sitemap/sitemap.module').then( m => m.SitemapModule)
  },

  {
    path: 'referidos',
    loadChildren: () => import('./routes/referidos/referidos.module').then( m => m.ReferidosModule)
  },

  {
    path: 'cdt-turbo',
    loadChildren: () => import('./routes/turbo/turbo.module').then( m => m.TurboModule)
  },

  {
    path: 'facil-cdt',
    loadChildren: () => import('./routes/turbo/turbo.module').then( m => m.TurboModule)
  },

  {
    path: 'club-cdt',
    loadChildren: () => import('./routes/club-cdt/club-cdt.module').then( m => m.ClubCdtModule)
  },

  {
    path: 'app/:any',
    redirectTo: 'app',
  },

];

const routes_ab_testing = [
  {
    path: 'ab-testing',
    loadChildren: () => import('./routes/ab-testing/ab-testing.module').then( m => m.AbTestingModule)
  },

  // {
  //   path: 'ab-testing/cdt-simulador',
  //   loadChildren: () => import('./routes/cdt-simulador-v2/cdt-simulador-v2.module').then( m => m.CdtSimuladorV2Module)
  // },
]

const routes_campaign_mejorcdt = [
  {
    path: 'cdt-cupido',
    loadChildren: () => import('./routes/cdt-cupido/cdt-cupido.module').then( m => m.CdtCupidoModule)
  }
]

const routes_form_sdk_tripetto = [
  {
    path: 'orden-inversion',
    loadChildren: () => import('./routes/tripetto-sdk/o-inversion/o-inversion.module').then( m => m.OInversionModule)
  },
  {
    path: 'cancelacion',
    loadChildren: () => import('./routes/tripetto-sdk/o-cancelacion/o-cancelacion.module').then( m => m.OCancelacionModule)
  },
  {
    path: 'datos-personales',
    loadChildren: () => import('./routes/tripetto-sdk/datos-personales/datos-personales.module').then( m => m.DatosPersonalesModule)
  },
  {
    path: 'datos-financieros',
    loadChildren: () => import('./routes/tripetto-sdk/datos-financieros/datos-financieros.module').then( m => m.DatosFinancierosModule)
  },
  {
    path: 'preguntas-ban100',
    loadChildren: () => import('./routes/tripetto-sdk/preguntas-ban100/preguntas-ban100.module').then( m => m.PreguntasBan100Module)
  },
  {
    path: 'preguntas-bancamia',
    loadChildren: () => import('./routes/tripetto-sdk/preguntas-bancamia/preguntas-bancamia.module').then( m => m.PreguntasBancamiaModule)
  },
  {
    path: 'preguntas-bancow',
    loadChildren: () => import('./routes/tripetto-sdk/preguntas-bancow/preguntas-bancow.module').then( m => m.PreguntasBancowModule)
  },
  {
    path: 'preguntas-coltefinanciera',
    loadChildren: () => import('./routes/tripetto-sdk/preguntas-coltefinanciera/preguntas-coltefinanciera.module').then( m => m.PreguntasColtefinancieraModule)
  },
  {
    path: 'preguntas-financieradannregional',
    loadChildren: () => import('./routes/tripetto-sdk/preguntas-dannregional/preguntas-dannregional.module').then( m => m.PreguntasDannregionalModule)
  },
  {
    path: 'preguntas/coltefinanciera/flamingo',
    loadChildren: () => import('./routes/tripetto-sdk/preguntas-flamingo/preguntas-flamingo.module').then( m => m.PreguntasFlamingoModule)
  },
  {
    path: 'preguntas-mibanco',
    loadChildren: () => import('./routes/tripetto-sdk/preguntas-mibanco/preguntas-mibanco.module').then( m => m.PreguntasMibancoModule)
  },
  {
    path: 'preguntas-bancomundomujer',
    loadChildren: () => import('./routes/tripetto-sdk/preguntas-mundomujer/preguntas-mundomujer.module').then( m => m.PreguntasMundomujerModule)
  },
  {
    path: 'preguntas-credifamilia',
    loadChildren: () => import('./routes/tripetto-sdk/preguntas-credifamilia/preguntas-credifamilia.module').then( m => m.PreguntasCredifamiliaModule)
  },
  {
    path: 'preguntas-cdturbo',
    loadChildren: () => import('./routes/tripetto-sdk/preguntas-cdturbo/preguntas-cdturbo.module').then( m => m.PreguntasColtefinancieraModule)
  }
]

const routes_form_v2: Routes = [
  {
    path: 'v2/datos-personales',
    loadChildren: () => import('./routes/formulario-nativo/datos-personales/datos-personales.module').then( m => m.DatosPersonalesModule)
  },
  {
    path: 'v2/datos-financieros',
    loadChildren: () => import('./routes/formulario-nativo/datos-financieros/datos-financieros.module').then( m => m.DatosFinancierosModule)
  },
  {
    path: 'v2/preguntas-ban100',
    loadChildren: () => import('./routes/formulario-nativo/preguntas-ban100/preguntas-ban100.module').then( m => m.PreguntasBan100Module)
  },
  {
    path: 'v2/preguntas-bancamia',
    loadChildren: () => import('./routes/formulario-nativo/preguntas-bancamia/preguntas-bancamia.module').then( m => m.PreguntasBancamiaModule)
  },
  {
    path: 'v2/preguntas-bancow',
    loadChildren: () => import('./routes/formulario-nativo/preguntas-bancow/preguntas-bancow.module').then( m => m.PreguntasBancowModule)
  },
  {
    path: 'v2/preguntas-coltefinanciera',
    loadChildren: () => import('./routes/formulario-nativo/preguntas-coltefinanciera/preguntas-coltefinanciera.module').then( m => m.PreguntasColtefinancieraModule)
  },
  {
    path: 'v2/preguntas-financieradannregional',
    loadChildren: () => import('./routes/formulario-nativo/preguntas-dannregional/preguntas-dannregional.module').then( m => m.PreguntasDannregionalModule)
  },
  {
    path: 'v2/preguntas/coltefinanciera/flamingo',
    loadChildren: () => import('./routes/formulario-nativo/preguntas-flamingo/preguntas-flamingo.module').then( m => m.PreguntasFlamingoModule)
  },
  {
    path: 'v2/preguntas-mibanco',
    loadChildren: () => import('./routes/formulario-nativo/preguntas-mibanco/preguntas-mibanco.module').then( m => m.PreguntasMibancoModule)
  },
  {
    path: 'v2/preguntas-bancomundomujer',
    loadChildren: () => import('./routes/formulario-nativo/preguntas-mundomujer/preguntas-mundomujer.module').then( m => m.PreguntasMundomujerModule)
  },
  {
    path: 'v2/preguntas-credifamilia',
    loadChildren: () => import('./routes/formulario-nativo/preguntas-credifamilia/preguntas-credifamilia.module').then( m => m.PreguntasCredifamiliaModule)
  },
  {
    path: 'v2/preguntas-cdturbo',
    loadChildren: () => import('./routes/formulario-nativo/preguntas-cdturbo/preguntas-cdturbo.module').then( m => m.PreguntasCdturboModule)
  }
]

const routes_aperture: Routes = [
  {
    path: 'confirma-tus-datos',
    loadChildren: () => import('./routes/confirma-tus-datos/confirma-tus-datos.module').then( m => m.ConfirmaTusDatosModule)
  },
  {
    path: 'bienvenida-usuario-retorno',
    loadChildren: () => import('./routes/bienvenida-usuario-retorno/bienvenida-usuario-retorno.module').then( m => m.WelcomeReturningUserModule)
  },
  {
    path: 'validar-identidad',
    loadChildren: () => import('./routes/validar-identidad/validar-identidad.module').then( m => m.ValidarIdentidadModule)
  },
  {
    path: 'confirmar-datos-truora',
    loadChildren: () => import('./routes/confirmar-datos-truora/confirmar-datos-truora.module').then( m => m.ConfirmarDatosTruoraModule)
  },
  {
    path: 'continuar-proceso',
    loadChildren: () => import('./routes/continuar-proceso/continuar-proceso.module').then( m => m.ContinuarProcesoModule)
  },
  {
    path: 'consulta-en-listas',
    loadChildren: () => import('./routes/consulta-en-listas/consulta-en-listas.module').then( m => m.ConsultaEnListasModule)
  },
  {
    path: 'sube-tus-documentos',
    loadChildren: () => import('./routes/sube-tus-documentos/sube-tus-documentos.module').then( m => m.SubeTusDocumentosModule)
  },
  {
    path: 'asegura-tu-tasa',
    loadChildren: () => import('./routes/asegura-tu-tasa/asegura-tu-tasa.module').then( m => m.AseguraTuTasaModule)
  },
  {
    path: 'transfiere-el-dinero',
    loadChildren: () => import('./routes/transfiere-el-dinero/transfiere-el-dinero.module').then( m => m.TransfiereElDineroModule)
  },
  {
    path: 'cdt-completado',
    loadChildren: () => import('./routes/cdt-completado/cdt-completado.module').then( m => m.CdtCompletadoModule)
  },
  {
    path: 'sube-el-comprobante-de-pago',
    redirectTo: '/transfiere-el-dinero'
  },
  {
    path: 'firma-los-documentos',
    redirectTo: '/asegura-tu-tasa'
  },
]

const CDTInfluencers = () => import('./routes/cdt-influencer/cdt-influencer.module').then(m => m.CdtInfluencerModule);
const routes_cdt_influencers: Routes = [
  {
    path: 'cdt-claudiauribe',
    loadChildren: () => import('./routes/cdt-claudiauribe/cdt-claudiauribe.module').then( m => m.CdtClaudiauribeModule)
  },
  { path: 'cdt-nicolasabril', loadChildren: CDTInfluencers },
  { path: 'cdt-financiatuvida', loadChildren: CDTInfluencers },
  { path: 'cdt-parejayfinanzas', loadChildren: CDTInfluencers },
  { path: 'cdt-alternativainversiones', loadChildren: CDTInfluencers },
  { path: 'cdt-arenalfa', loadChildren: CDTInfluencers }
];

const CDTBanksModuleLoader = () => import('./routes/cdt-banco-v2/cdt-banco-v2.module').then(m => m.CdtBancoV2Module);
const routes_cdt_banks: Routes = [
  { path: 'cdt-agrario', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-coltefinanciera', loadChildren: CDTBanksModuleLoader, },
  { path: 'cdt-pichincha', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-credifinanciera', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-ban100', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-ibrcredifinanciera', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-bancamia', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-bancolombia', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-bogota', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-popular', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-itau', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-gnbsudameris', redirectTo: '/cdt-sudameris' },
  { path: 'cdt-sudameris', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-bbva', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-occidente', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-cajasocial', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-davivienda', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-avvillas', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-bancow', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-bancoomeva', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-finandina', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-falabella', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-coopcentral', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-mundomujer', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-serfinanza', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-colpatria', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-mibanco', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-dannregional', loadChildren: CDTBanksModuleLoader },
  { path: 'cdt-credifamilia', loadChildren: CDTBanksModuleLoader }
]

const MejoresCDTsMesModuleLoader = () => import('./routes/mejores-cdt-mes/mejores-cdt-mes.module').then(m => m.MejoresCdtMesModule);
const routes_best_banks_month: Routes = [
  { path: 'mejores-cdt-febrero-2025', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-enero-2025', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-diciembre-2024', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-noviembre-2024', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-octubre-2024', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-septiembre-2024', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-agosto-2024', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-julio-2024', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-junio-2024', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-mayo-2024', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-abril-2024', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-marzo-2024', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-febrero-2024', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-enero-2024', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-diciembre-2023', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-noviembre-2023', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-octubre-2023', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-septiembre-2023', loadChildren: MejoresCDTsMesModuleLoader},
  { path: 'mejores-cdt-agosto-2023', loadChildren: MejoresCDTsMesModuleLoader },
  { path: 'mejores-cdt-julio-2023', loadChildren: MejoresCDTsMesModuleLoader },
  { path: 'mejores-cdt-junio-2023', loadChildren: MejoresCDTsMesModuleLoader },
  { path: 'mejores-cdt-mayo-2023', loadChildren: MejoresCDTsMesModuleLoader },
  { path: 'mejores-cdt-abril-2023', loadChildren: MejoresCDTsMesModuleLoader },
  { path: 'mejores-cdt-marzo-2023', loadChildren: MejoresCDTsMesModuleLoader },
  { path: 'mejores-cdt-febrero-2023', loadChildren: MejoresCDTsMesModuleLoader },
  { path: 'mejores-cdt-enero-2023', loadChildren: MejoresCDTsMesModuleLoader }
]

const blogElementModuleLoader = () => import('./routes/blog-element/blog-element.module').then(m => m.BlogElementModule);
const blog: Routes = [
  { path: 'blog/categorias', loadChildren: () => import('./routes/blog/blog.module').then( m => m.BlogModule) },
  { path: 'blog/categoria/:id', loadChildren: () => import('./routes/blog/blog.module').then( m => m.BlogModule) },
  { path: 'politica-de-privacidad', loadChildren: blogElementModuleLoader },
  /* Libro gratuito */
  { path: 'psicologia-de-las-inversiones', loadChildren: blogElementModuleLoader},
  { path: 'tecnicas-y-estrategias-para-ahorrar-e-invertir', loadChildren: blogElementModuleLoader },
  { path: 'de-la-planificacion-a-la-accion', loadChildren: blogElementModuleLoader },
  { path: 'mis-mejores-cdts', loadChildren: blogElementModuleLoader },
  { path: 'todo-lo-que-debes-saber-de-los-cdt', loadChildren: blogElementModuleLoader },
  /* Servicios de mejorCDT */
  { path: 'cdt-recargable', loadChildren: blogElementModuleLoader },
  { path: 'retira-tu-cdt-ante-una-emergencia', loadChildren: blogElementModuleLoader },
  {
    path: 'cdt-flexible',
    loadChildren: () => import('./routes/cdt-flexible/cdt-flexible.module').then( m => m.CdtFlexibleModule)
  },
  /* Sobre los CDTS */
  { path: 'nubank-ofrece-cdt-en-el-2025', loadChildren: blogElementModuleLoader },
  { path: 'cual-es-el-monto-minimo-para-invertir-en-un-cdt-en-colombia', loadChildren: blogElementModuleLoader },
  { path: 'cdt-nubank', loadChildren: blogElementModuleLoader },
  { path: 'cdt-para-empresas', loadChildren: blogElementModuleLoader },
  { path: 'cdt-vs-inflacion', loadChildren: blogElementModuleLoader },
  { path: 'como-calcular-los-intereses-de-un-cdt', loadChildren: blogElementModuleLoader },
  { path: 'como-funciona-un-cdt-en-colombia', loadChildren: blogElementModuleLoader },
  { path: 'cuales-son-los-diferentes-usos-de-un-cdt', loadChildren: blogElementModuleLoader },
  { path: 'cuanto-paga-un-cdt', loadChildren: blogElementModuleLoader },
  { path: 'historia-de-los-cdts', loadChildren: blogElementModuleLoader },
  { path: 'por-que-el-banco-me-pide-tantos-documentos-para-abrir-mi-cdt', loadChildren: blogElementModuleLoader },
  { path: 'por-que-invertir-en-un-cdt', redirectTo: 'por-que-cdt' },
  { path: 'por-que-cdt', loadChildren: blogElementModuleLoader },
  { path: 'que-bancos-tienen-cdt-en-colombia', loadChildren: blogElementModuleLoader },
  { path: 'que-es-un-cdt', loadChildren: blogElementModuleLoader },
  { path: 'se-puede-retirar-el-dinero-de-tu-cdt-antes-del-vencimiento', loadChildren: blogElementModuleLoader },
  { path: 'tipos-beneficios-y-requisitos-para-un-cdt', loadChildren: blogElementModuleLoader },
  { path: 'un-menor-de-edad-puede-abrir-un-cdt', loadChildren: blogElementModuleLoader },
  { path: 'ventajas-de-un-cdt-como-inversion', loadChildren: blogElementModuleLoader },
  { path: 'los-saldos-de-los-cdts-siguen-creciendo-en-2024-y-aqui-te-contamos-por-que', loadChildren: blogElementModuleLoader },
  { path: 'recomendaciones-para-hacer-transacciones-financieras-digitales-seguras', loadChildren: blogElementModuleLoader },
  /* Sobre tus finanzas */
  { path: 'inversiones-seguras', loadChildren: blogElementModuleLoader },
  { path: 'cdts-o-invertir-en-dolares-bitcon-o-acciones', loadChildren: blogElementModuleLoader },
  { path: 'radar-financiero', loadChildren: blogElementModuleLoader },
  { path: 'claves-para-ahorrar-en-un-fondo-de-emergencia', loadChildren: blogElementModuleLoader },
  { path: 'como-han-cambiado-los-bancos-de-nombre-a-lo-largo-de-la-historia-de-colombia', loadChildren: blogElementModuleLoader },
  { path: 'consejos-padre-exito-financiero-hijos', loadChildren: blogElementModuleLoader },
  { path: 'consejos-para-maximizar-tus-ganancias-segun-el-ciclo-economico', loadChildren: blogElementModuleLoader },
  { path: 'mitos-y-verdades-de-la-declaracion-de-renta-de-los-cdts', loadChildren: blogElementModuleLoader },
  { path: 'mitos-y-verdades-sobre-la-retencion-en-la-fuente', loadChildren: blogElementModuleLoader },
  { path: 'que-es-fogafin', loadChildren: blogElementModuleLoader },
  { path: 'que-es-un-fondo-de-emergencia', loadChildren: blogElementModuleLoader },
  { path: 'que-es-una-calificacion-de-riesgo', loadChildren: blogElementModuleLoader },
  { path: 'sillicon-valley-bank-seguro-financiero', loadChildren: blogElementModuleLoader },
  { path: 'todo-lo-que-debes-saber-sobre-deceval', loadChildren: blogElementModuleLoader },
  { path: 'todo-lo-que-debes-saber-sobre-el-impuesto-4x1-000-en-colombia', loadChildren: blogElementModuleLoader },
  { path: 'guia-para-inversionistas-en-cdt-declaracion-renta-simplificada', loadChildren: blogElementModuleLoader },
  /* Novedades de los CDT */
  { path: 'el-impacto-de-las-tasas-de-interes-del-banco-de-la-republica-en-los-cdts', loadChildren: blogElementModuleLoader },
  { path: 'como-se-comportan-los-cdt-en-colombia-vs-en-el-mundo', loadChildren: blogElementModuleLoader },
  { path: 'en-la-renovacion-de-un-cdt-debo-pagar-el-4x1000', loadChildren: blogElementModuleLoader },
  { path: 'es-tu-momento-para-invertir', loadChildren: blogElementModuleLoader },
  { path: 'historico-de-las-tasas-de-los-cdt-a-traves-de-los-anos', loadChildren: blogElementModuleLoader },
  { path: 'las-tasas-de-los-cdt-han-subido', loadChildren: blogElementModuleLoader },
  { path: 'solo-3-de-cada-100-personas-tienen-un-cdt', loadChildren: blogElementModuleLoader },
  { path: 'es-tu-momento-para-invertir', loadChildren: blogElementModuleLoader },
  { path: 'por-que-hay-unos-cdt-mas-rentables-que-otros', loadChildren: blogElementModuleLoader },
  { path: 'por-que-las-tasas-de-los-cdt-bajaron', loadChildren: blogElementModuleLoader },
  { path: 'cdt-vs-cuentas-de-ahorro-de-alto-rendimiento', loadChildren: blogElementModuleLoader },
  { path: 'cuentas-de-alto-rendimiento-vs-cdt-descubre-cual-es-la-mejor-opcion-para-ti', loadChildren: blogElementModuleLoader },
];

const routes_alerts_components: Routes = [
  {
    path: 'no-podemos-continuar-con-tu-proceso',
    loadChildren: () => import('./routes/no-podemos-continuar-con-tu-proceso/no-podemos-continuar-con-tu-proceso.module').then( m => m.NoPodemosContinuarConTuProcesoModule)
  },
  {
    path: 'firma-los-documentos-en-fisico',
    loadChildren: () => import('./routes/no-podemos-continuar-con-tu-proceso/no-podemos-continuar-con-tu-proceso.module').then( m => m.NoPodemosContinuarConTuProcesoModule)
  },
  {
    path: 'bloqueo-temporal',
    loadChildren: () => import('./routes/bloqueo-temporal/bloqueo-temporal.module').then( m => m.BloqueoTemporalModule)
  }
]

const routes_landings_pages: Routes = [
  {
    path: 'a-unos-minutos-de-abrir-tu-cdt',
    loadChildren: () => import('./routes/a-unos-minutos-de-abrir-tu-cdt/a-unos-minutos-de-abrir-tu-cdt.module').then( m => m.AUnosMinutosDeAbrirTuCdtModule)
  },
  {
    path: 'credifinanciera-es-ban-100',
    loadChildren: () => import('./routes/credifinanciera-es-ban-100/credifinanciera-es-ban-100.module').then( m => m.CredifinancieraEsBan100Module)
  },
  {
    path: 'preguntas-frecuentes',
    loadChildren: () => import('./routes/preguntas-frecuentes/preguntas-frecuentes.module').then( m => m.PreguntasFrecuentesModule)
  },
  {
    path: 'libro-gratuito',
    loadChildren: () => import('./routes/libro-gratuito/libro-gratuito.module').then( m => m.LibroGratuitoModule)
  },
  { path: 'e-book', redirectTo: '/libro-gratuito'},
  {
    path: 'pioneros-finanzas-emocionales',
    loadChildren: () => import('./routes/pioneros-finanzas-emocionales/pioneros-finanzas-emocionales.module').then( m => m.PionerosFinanzasEmocionalesModule)
  },
  {
    path: 'life-miles',
    loadChildren: () => import('./routes/life-miles/life-miles.module').then( m => m.LifeMilesModule)
  },
  {
    path: 'jumbo-cdt',
    loadChildren: () => import('./routes/jumbo-cdt/jumbo-cdt.module').then( m => m.JumboCdtModule)
  },
  {
    path: 'promos/volvieron-las-tasas-del-15-de-rentabilidad',
    redirectTo: '/dias-azules'
  },
  {
    path: 'dias-azules',
    loadChildren: () => import('./routes/dias-azules/dias-azules.module').then( m => m.DiasAzulesModule)
  },
  {
    path: 'beneficios-ban100',
    loadChildren: () => import('./routes/beneficios-ban100/beneficios-ban100.module').then( m => m.BeneficiosBan100Module)
  },
  {
    path: 'trabajo',
    loadChildren: () => import('./routes/trabajo/trabajo.module').then( m => m.TrabajoModule)
  },
  {
    path: 'prensa',
    loadChildren: () => import('./routes/prensa/prensa.module').then( m => m.PrensaModule)
  },
  {
    path: 'golf18',
    loadChildren: () => import('./routes/evento-golf18/evento-golf18.module').then( m => m.EventoGolf18Module)
  },
  {
    path: 'sueldo-inteligente',
    loadChildren: () => import('./routes/sueldo-inteligente/sueldo-inteligente.module').then( m => m.SueldoInteligenteModule)
  },
  {
    path: 'moneycon',
    loadChildren: () => import('./routes/moneycon/moneycon.module').then( m => m.MoneyconModule)
  },
  {
    
    path: 'te-esperamos-en-la-moneycon',
    loadChildren: () => import('./routes/te-esperamos-en-la-moneycon/te-esperamos-en-la-moneycon.module').then( m => m.TeEsperamosEnLaMoneyconModule)
  }
]

const widgets_frame: Routes = [
  {
    path: 'widget-simulador',
    loadChildren: () => import('./resources-widget/widget-frame-simulador/widget-frame-simulador.module').then( m => m.WidgetFrameSimuladorModule)
  }
]

const routes_404: Routes = [
  {
    path: '',
    loadChildren: () => import('./routes/home/home.module').then( m => m.HomeModule)
  },
  {
    path: '404',
    loadChildren: () => import('./routes/pagina-404/pagina-404.module').then( m => m.Pagina404Module)
  },
  {
    path: '**', // Ruta comodín para redirección
    redirectTo: '/404' // Redirige a la ruta principal (home) si la ruta no coincide con ninguna otra definida
  },

]


const routes: Routes = routes_app.concat(
  routes_form_sdk_tripetto,
  routes_form_v2,
  routes_aperture,
  routes_cdt_banks,
  routes_cdt_influencers,
  routes_ab_testing,
  routes_campaign_mejorcdt,
  routes_best_banks_month,
  blog,
  routes_alerts_components,
  routes_landings_pages,
  widgets_frame,
  routes_404,
);

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule,
  ]
})

export class AppRoutingModule { }
