<div class="modal-body modal-lg px-4">
  <div class="content-modal-theme">
    <div class="container-fluid px-0">
      <div class="w-100 text-end">
        <i class="bi bi-x-lg cursor-pointer text-black-10" (click)="this.activeModal.close()"></i>
      </div>
      <h5 class="title-5 text-blue-100 text-center">
        ¿Nos podrías decir por qué no abriste tu inversión?
      </h5>
      <form class="my-3">
        <div class="row align-items-center mb-3"
          *ngFor="let item of reasons; let i = index"
          (mouseenter)="hoveredReason = item.id"
          (mouseleave)="hoveredReason = null"
          (click)="onSelectOption(item?.tag)">
          <input
            type="radio"
            id="reason-{{ item.id }}"
            name="reasons"
            [value]="item.id"
            [(ngModel)]="selectedReason"
            class="d-none"
          />
          <div class="col-9">
            <label for="reason-{{ item.id }}" class="w-100 cursor-pointer text-size-md">
              {{ item.text }}
            </label>
          </div>
          <div class="col-3 text-end">
            <label for="reason-{{ item.id }}" class="cursor-pointer">
              @if(selectedReason === item.id) {
                <i class="bi bi-check-circle-fill text-blue-100 text-size-lg"></i>
              }
              @else {
                <i
                  class="bi bi-check-circle-fill text-black-10 text-size-lg"
                  [ngClass]="{
                    'text-black-50': hoveredReason === item.id && selectedReason !== item.id
                  }"
                ></i>
              }
            </label>
          </div>
        </div>
      </form>
      <button type="button" class="btn btn-lg btn-primary-blue w-100 my-2" (click)="onResumeInvestment()">
        Volver a mi inversión
        <i class="bi bi-arrow-right"></i>
      </button>
    </div>
  </div>
</div>
