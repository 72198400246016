import { Component, OnInit } from '@angular/core';
import { GtmService } from '@apiShared/analytics/gtm';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsMethods_Services } from '@utils/utils';

@Component({
  selector: 'app-reason-cancellation-opening-cdt',
  templateUrl: './reason-cancellation-opening-cdt.component.html',
  styleUrls: ['./reason-cancellation-opening-cdt.component.css']
})
export class ReasonCancellationOpeningCDTComponent implements OnInit {
  reasons: any = [
    { id: 'RC1', text: 'No encontré un plazo que se ajuste a mí.', tag: 'ga_modal_cancelacion_1' },
    { id: 'RC2', text: 'Este banco no me genera confianza.', tag: 'ga_modal_cancelacion_2' },
    { id: 'RC3', text: 'Esta tasa no es atractiva.', tag: 'ga_modal_cancelacion_3' },
    { id: 'RC4', text: 'Aún no estoy seguro de invertir.', tag: 'ga_modal_cancelacion_4' },
    { id: 'RC5', text: 'Solo estoy probando la plataforma.', tag: 'ga_modal_cancelacion_5' }
  ];

  selectedReason: string | null = null;
  hoveredReason: string | null = null;

  data: any;

  constructor(
    public activeModal: NgbActiveModal,
    public utils: UtilsMethods_Services,
    private gtm: GtmService
  ) {}

  ngOnInit(): void {}

  onResumeInvestment(): void {
    this.onSelectReasonGTM('ga_modal_cancelacion_resume_return');
    this.activeModal.close();
    this.utils.openCDT({
      bank    : this.data.bank,
      term    : this.data.place,
      options : this.data.data,
      turbo   : this.data.turbo
    })
  }

  onSelectOption(tag: string): void {
    this.onSelectReasonGTM(tag);
    setTimeout(() => {
      this.activeModal.close();
    }, 1100);
  }


  onSelectReasonGTM(tag: string): void {
    this.gtm.triggerGTMEvent(tag, 'ReasonCancellationOpeningCDTComponent');
  }
}
